import { useRef, useState } from 'react';
import './App.css';
import { Formik ,Form,Field,ErrorMessage} from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
function App() {
 const [i,setI]=useState(0);
 const form=useRef(null);
 const form1=useRef(null);
 const message=useRef(null);
 const total=useRef(null);
 const validateSchema=yup.object().shape({
  email: yup.string().email().required().matches(/.+\.com$/, 'Email must end with ".com"'),
  name:yup.string().min(8).required(),
  phone:yup.string().required().matches(/^06\d{8}$/, 'Must start with 06 and have 10 digits'),
  company:yup.string().required(),
  text:yup.string()

})

 function handleform1(){

  form.current.style.bottom='0%';
  total.current.style.background=' radial-gradient(rgba(88, 8, 99,0.4), rgba(88, 8, 99, 0.4)),#ededed'
 }
 function handleform2(){
  form.current.style.bottom='-200%';
  total.current.style.background='#ededed';
 }
 function handlemessage1(){
  message.current.style.bottom='30%';
  form.current.style.background=' radial-gradient(rgba(88, 8, 99,0.4), rgba(88, 8, 99, 0.4)),#ededed'
 total.current.style.pointerEvents='auto'
 message.current.style.pointerEvents='auto';
 emailjs.sendForm('service_8mv4rwc', 'template_nxuj0yn', form1.current, 'TEaSLuNid57flIu52')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
 }
 function handlemessage2(){
  message.current.style.bottom='-200%'; 
  form.current.style.background='#ededed';
  total.current.style.pointerEvents='auto';
 }
  function moveRight(){
    const comments=Array.from(document.querySelectorAll('.clients-comment p'));
   if(i<parseInt(comments.length-1)){
    comments[i].style.display='none';
    comments[i+1].style.display=`block`;
    setI(i+1);
   }
  }
  function moveLeft(){
    const comments=Array.from(document.querySelectorAll('.clients-comment p'));
    if(i>0){
      comments[i].style.display=`none`;
      comments[i-1].style.display='block';
      setI(i-1);
     }
  }
 
  return (
    <div className="App">
     <div className='totale-div' ref={total} onClick={(event)=>{
      if(getComputedStyle(form.current).bottom>='0px'&&!form.current.contains(event.target)&&!message.current.contains(event.target)){
        handleform2();
      }
     }}>
       <div id='logo'>
        
          </div>
      <header>
       
        <div  className='chaine'>
    <h2  className='chaine1'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg>UX/UI <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Visual Identity & branding <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> website devlopment <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Marketing strategie</h2>
    <h2  className='chaine2'>UX/UI <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Visual Identity & branding <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> website devlopment <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Marketing strategie</h2>
    <h2  className='chaine3'>UX/UI <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Visual Identity & branding <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> website devlopment <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Marketing strategie</h2>
    <h2  className='chaine4'>UX/UI <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Visual Identity & branding <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> website devlopment <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Marketing strategie</h2>
    
    </div>
    <div className='in-header'>
      <h1>
        Elevate Your Brand</h1><h1> with <span className='Dg'>dg marketing</span>
      </h1>
      <p>Your vision, our execution Let's make is a reality</p>
    </div>
  <div  className='chaine' >
  <h2  className='chaine1'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg>UX/UI <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Visual Identity & branding <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> website devlopment <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Marketing strategie</h2>
    <h2  className='chaine2'>UX/UI <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Visual Identity & branding <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> website devlopment <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Marketing strategie</h2>
    <h2  className='chaine3'>UX/UI <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Visual Identity & branding <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> website devlopment <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Marketing strategie</h2>
    <h2  className='chaine4'>UX/UI <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Visual Identity & branding <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> website devlopment <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg> Marketing strategie</h2>
    </div>    </header>
    <section className='top-container'>
    <h2>about us</h2>
    <div className='about'>
      <p>At <span className='Dg'>DG Marketing</span> we're growth experts. We help businesses like your reach their full potential through innovative marketing strategies that deliver results.
      We're more than just a marketing agency. We're your partnet in success. We take the time to understand your unique needs and tailor our solutions to fit your goals. Because we believe that every business has the pothential for greatness.
        </p>
      <p>Ready to take your to the next level? Let's make it happen. Contact us today to schedule a consulation and unlock your business's full potentiel.</p>
    </div>
    </section>
     <section className='clients-review'>
      <h2>What our clients are saying.</h2>
      <div className='buttons'><i className='fas fa-arrow-left' onClick={moveLeft}></i><i className='fas fa-arrow-right' onClick={moveRight}></i></div>
      <div className='clients-comment'>
        <p> "1What is the most meaningful community you’ve been apart of? Name it here.

What is your best memory of that community?

How well did that community welcome new people?

How well did that community create deep bonds between members?

ensure you give view access to the google document "</p>
        <p>"2What is the most meaningful community you’ve been apart of? Name it here.

What is your best memory of that community?

How well did that community welcome new people?

How well did that community create deep bonds between members?

ensure you give view access to the google document "</p>
        <p>
        "3What is the most meaningful community you’ve been apart of? Name it here.

What is your best memory of that community?

How well did that community welcome new people?

How well did that community create deep bonds between members?

ensure you give view access to the google document"
        </p>
        <p>
       " 4What is the most meaningful community you’ve been apart of? Name it here.

What is your best memory of that community?

How well did that community welcome new people?

How well did that community create deep bonds between members?

ensure you give view access to the google document
        "</p>
      </div>
     </section>
     <section className='lets-talk' >
      <h2>
      Begin your journey to growth.
      </h2>
      <a className='btn' onClick={handleform1}>
        Let's talk <i className='fas fa-arrow-right'></i>
      </a>
     </section>
     <section className='social-media'>
     <ul>
     <li><i className='far fa-envelope'/><a href={`https://gmail.google.com/mail/?view=cm&to=${encodeURIComponent('Letstalk@thedgmarketing.com')}`}   target="_blank">Letstalk@thedgmarketing.com</a></li>
     <li><i className='fab fa-instagram'/><a href='https://instagram.com/dgmarketing.ma?igshid=MzRlODBiNWFlZA=='  target="_blank">/Thedgmarketing.com</a></li>
     <li><i className='fab fa-behance'/><a href='https://www.behance.net/thedgmarketing'  target="_blank">/thedgmarketing</a></li>
     </ul>
     </section>
     <footer>
      <ul>
        <li>&copy; DG MARKETING</li> 
        <li>Privecy Policy</li>
        <li>All Rigthts </li>
      </ul>
     </footer>
     <div className='form' ref={form}  >
      <div>
        <h2>Ready to make big things happen?</h2>
        <h3>Contact us today for free consultation</h3>
        </div>
        <Formik initialValues={{email:'',name:'',phone:'',company:'',text:''}} validationSchema={validateSchema} onSubmit={handlemessage1}>
        <Form ref={form1}>
        <div id='logo'>
        
        </div>
          <div><Field name='email' type='text' placeholder='*Email'/>     
<Field name='phone' type='text' placeholder='*Phone'/>            
</div>
          <div><Field name='name' type='text' placeholder='*Name'/>    
<Field type='text' name='company' placeholder='*Company'/>           
</div>
        <Field name='text' as='textarea' placeholder='Message (optional)'></Field>
       <div style={{display:'flex',flexWrap:'wrap'}}><ErrorMessage name='email' component='span'/><ErrorMessage name='phone' component='span'/><ErrorMessage name='name' component='span'/> <ErrorMessage name='company' component='span'/></div> 
        <p>In submitting this form you are agreeing to Huge's Privacy Policy</p>
        <button style={{borderStyle:'none'}} className='btn' type='submit' >Send <i className='fas fa-arrow-right'></i> </button>
        </Form>
        </Formik>
    <div className='social-media2'>
      <ul>
      <li><i className='far fa-envelope'/><a href={`https://gmail.google.com/mail/?view=cm&to=${encodeURIComponent('Letstalk@thedgmarketing.com')}`} >Letstalk@thedgmarketing.com</a></li>
     <li><i className='fab fa-instagram'/><a href='https://instagram.com/dgmarketing.ma?igshid=MzRlODBiNWFlZA=='  target="_blank">/Thedgmarketing.com</a></li>
     <li><i className='fab fa-behance'/><a href='https://www.behance.net/thedgmarketing'  target="_blank">/thedgmarketing</a></li>
        </ul>
        </div>
        </div>
        <div className='messageShow' ref={message}  onClick={handlemessage2}>
          <div id='logo' style={{width:'50%',top:'10%'}}>
        
          </div>
        <i className='circle'></i>
        <p>"Thank you for reaching out! Our team is excited to connect with you and discuss how we can help take your business to the next level. We'll be in touch soon to schedule a consultation and start exploring the possibilities."</p>
        <h4 style={{display:'flex' , alignItems:'center',gap:'3px'}}><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
<path d="M12.3836 5.21422V0H7.16942V3.49604C7.08501 4.33547 6.72592 5.00498 6.26867 5.53015C5.44793 4.48147 5.2145 3.33354 5.2145 3.33354V0H0V5.21422H3.49604C4.36764 5.30193 5.05585 5.68549 5.58981 6.16776C4.66817 6.88484 3.69015 7.16914 3.69015 7.16914H0V12.3834H5.21422V9.04983C5.35143 8.12213 5.74021 7.40835 6.22055 6.86285C6.89859 7.76359 7.16914 8.69349 7.16914 8.69349V12.3836H12.3834V7.16942H9.04983C8.15568 7.03717 7.4606 6.6712 6.92306 6.21505C7.95221 5.43776 9.04983 5.2145 9.04983 5.2145H12.3834L12.3836 5.21422Z" fill="#570861"/>
</svg>DG marketing</h4>
      </div>

     
    </div>
     </div>
    
  );
}

export default App;
